<template>
  <v-list class="employee-list" v-bind="$attrs">
    <v-fade-transition hide-on-leave>
      <LoadingState v-if="loading" />
      <EmptyState v-else-if="isEmpty" />
    </v-fade-transition>

    <EmployeeListItem
      v-for="employee in employees"
      :key="employee.id"
      :employee="employee"
      :hide-status="false"
      @click="$emit('click:employee', employee)"
    />
  </v-list>
</template>

<script>
import { computed } from "vue";
import { orderBy, uniqBy } from "lodash";

import { makeSearchProps, useSearch } from "@/composables/employee/search";

import EmptyState from "./_EmptyState.vue";
import LoadingState from "./_LoadingState.vue";
import EmployeeListItem from "../EmployeeListItem.vue";

export default {
  name: "EmployeeStatusList",
  components: { EmployeeListItem, EmptyState, LoadingState },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    ...makeSearchProps(),
  },
  setup(props) {
    const { searchedEmployees, isEmpty } = useSearch(props);

    const orderedShifts = computed(() =>
      orderBy(props.shifts, [(x) => x.scheduledStartAt ?? ""], ["asc"])
    );

    // Active employees
    const activeEmployees = computed(() =>
      orderBy(
        searchedEmployees.value.filter((x) => x.clockedIn || x.isOnBreak),
        [(x) => x.lastActionAt ?? ""],
        ["desc"]
      )
    );

    const allInactiveEmployees = computed(() =>
      orderBy(
        searchedEmployees.value.filter((x) => !(x.clockedIn || x.isOnBreak)),
        [(x) => x.lastActionAt ?? ""],
        ["desc"]
      )
    );

    // Scheduled non active
    const inactiveEmployees = computed(() => {
      if (!orderedShifts.value?.length) {
        return allInactiveEmployees.value;
      }

      const scheduledEmployees = [];

      orderedShifts.value.forEach((shift) => {
        const employee = allInactiveEmployees.value.find(
          (emp) => emp?.id === shift?.employee?.id
        );

        if (employee) {
          const alreadyScheduledEmployee = scheduledEmployees.find(
            (emp) => emp.id === employee.id
          );
          if (!alreadyScheduledEmployee) {
            scheduledEmployees.push(employee);
          }
        }
      });

      return scheduledEmployees;
    });

    // All the rest of employees
    const notScheduledEmployees = computed(() => {
      if (!orderedShifts.value?.length) {
        return [];
      }

      return allInactiveEmployees.value.filter((employeeToCheck) => {
        const existingScheduledEmployee = inactiveEmployees.value.find(
          (emp) => emp.id === employeeToCheck.id
        );

        return !existingScheduledEmployee;
      });
    });

    const employees = computed(() =>
      uniqBy(
        [
          ...activeEmployees.value,
          ...inactiveEmployees.value,
          ...notScheduledEmployees.value,
        ],
        (e) => e.id
      )
    );

    return {
      employees,
      isEmpty,
    };
  },
};
</script>

<style lang="scss">
@import "./index.scss";
</style>
